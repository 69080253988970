<template>
    <div class='loader'>
        <div class='loader-line a-loop'></div>
    </div>
</template>
<script>
export default {
    name: 'LineLoader',
}
</script>

<style scoped>
    .loader {
        width: 100%;
        height: 4px;
        background: #e0e0e0;
        overflow: hidden;
    }
    .loader-line {
        height: 4px;
        background: #AEAEAE;
    }
    .a-loop {
        animation: 3s infinite loop cubic-bezier(0.420, 0.000, 0.580, 0.700);
    }
    @keyframes loop {
        0% {
            width: 0%;
            transform: translateX(0%);
        }
        7% {
            width: 10%;
            transform: translateX(10%);
        }
        40% {
            width: 50%;
        }
        50% {
            transform: translateX(80%);
        }        
        75% {
            width: 100%;
        }
        100% {
            transform: translateX(100%);
        }
    }
</style>
